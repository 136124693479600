import React from 'react';
import { Text, Link } from '@geist-ui/react';
import makeStyles from '../makeStyles';
import * as Icons from 'react-feather';

const useStyles = makeStyles((ui) => ({
  root: {
    padding: '8px 42px',
    borderTop: `solid 1px ${ui.palette.accents_2}`,
    textAlign: 'center',
    display: 'flex',
    flexdirection: 'row',
    alignItems: 'center'
  },
  logo: {
    display: 'flex',
    width: '32px',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${ui.palette.accents_2}`,
    borderRadius: '50%',
    margin: '0 6px 0 0'
  },
  [`@media screen and (min-width: ${ui.layout.pageWidthWithMargin})`]: {
    root: {
      textAlign: 'start !important'
    }
  }
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <Icons.Home size={16} />
      </div>
      <Text>
        Made with{' '}
        <Link href="https://team.ncuos.com" target="_blank" rel="noopener" underline>
          NCUHOME
        </Link>
        .
      </Text>
    </div>
  );
};

export default Footer;
