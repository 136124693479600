import { Grid, Text, Spacer, Card, Avatar, Tooltip } from '@geist-ui/react';
import makeStyles from '../makeStyles';
import SplashCard from '../components/SplashCard';
import Header from '../components/Header';
import Content from '../components/Content';
import * as Icons from 'react-feather';
import { usePlatform } from '../hooks/usePlatform';
import { Fragment, useRef, useState } from 'react';
import { PhotoSlider } from 'react-photo-view';
import { noop } from 'lodash';

import dayjs from 'dayjs';
import EmptyCard from '../components/EmptyCard';
import type { dataType } from 'react-photo-view/dist/types';
import Title from '../components/Title';

import '../components/index.css';

type Item = {
  id: string;
  start: string;
  end: string;
  title: string;
  extra: {
    type: string;
    props: {
      url: string;
    };
  };
  image: string;
};

let data = [
  {
    id: '1',
    start: '2021-04-05 09:05:07',
    end: '2021-04-10 06:00:07',
    title: '我也喜欢二次元',
    extra: {
      type: 'web',
      props: {
        url: 'http://hack.ncuos.com/'
      }
    },
    image: 'https://i.pinimg.com/564x/2e/05/6e/2e056e0dbc583d612c2d02a6da23450a.jpg'
  },
  {
    id: '2',
    start: '2021-04-05 09:05:07',
    end: '2021-04-10 06:00:07',
    title: 'Hackweek 宣传头图',
    extra: {
      type: 'web',
      props: {
        url: 'http://hack.ncuos.com/'
      }
    },
    image: '	http://incu-orbit.ncuos.com/1617628291372.87EA0FF9E7CD0E99A7CF4EBB4B619199.png'
  },
  {
    id: '3',
    start: '2021-04-05 09:05:07',
    end: '2021-04-30 06:00:07',
    title: 'MKM 厨即将占领我校',
    extra: {
      type: 'web',
      props: {
        url: 'http://hack.ncuos.com/'
      }
    },
    image: 'https://i.pinimg.com/564x/3e/bc/2d/3ebc2dc8bc47559befc9f16eb6fd0816.jpg'
  },
  {
    id: '4',
    start: '2021-05-10 09:05:07',
    end: '2021-06-30 06:00:07',
    title: 'MKM 厨即将轰炸',
    extra: {
      type: 'web',
      props: {
        url: 'http://hack.ncuos.com/'
      }
    },
    image: 'https://i.pinimg.com/564x/3e/bc/2d/3ebc2dc8bc47559befc9f16eb6fd0816.jpg'
  }
];

let suggestions = [
  {
    title: '开屏广告如何设计？',
    link: 'http://www.woshipm.com/it/1345032.html',
    img: 'http://image.woshipm.com/favicon.ico'
  },
  {
    title: '品牌广告开屏产品营销指南',
    link:
      'http://yingxiao.baidu.com/new/home/product/details?id=7251&ly=article_detailzx&castk=LTE%3D',
    img: 'https://www.baidu.com/favicon.ico'
  },
  {
    title: '品牌广告开屏产品营销指南',
    link: 'http://yingxiao.baidu.com',
    img: 'https://www.baidu.com/favicon.ico'
  }
];

const Splash = () => {
  const classStyle = useStyles();
  const { platform } = usePlatform();
  const [showView, setShowView] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const listRef = useRef<string[]>([]);
  // const { setVisible, bindings } = useModal();
  // const [detail, setDetail] = useState<any>({});

  const renderCards = (icon: JSX.Element, title: string, filterData: Item[]) => {
    if (filterData.length === 0) return null;

    if (listRef.current.length < data.length) {
      listRef.current = [...listRef.current, ...filterData.map((i) => i.id)];
    }

    return (
      <>
        <Title title={`${title} · ${filterData.length}`} icon={icon}/>
        <Grid.Container gap={2} justify="flex-start">
          {filterData.map((item) => (
            <Grid xs={24} md={24} key={item.id}>
              <SplashCard
                id={item.id}
                title={item.title}
                start={dayjs(item.start).valueOf()}
                end={dayjs(item.end).valueOf()}
                image={item.image}
                webURL={item.extra.props.url}
                openImage={() => {
                  setPhotoIndex(listRef.current.indexOf(item.id) || 0);
                  setShowView(true);
                }}
              />
            </Grid>
          ))}
        </Grid.Container>
        <Spacer y={2} />
      </>
    );
  };

  return (
    <>
      <Header
        title="iNCU 开屏"
        description="设置 APP 启动头图"
        actions={[{ name: '创建开屏', icon: <Icons.Plus />, onPress: noop, type: 'success' }]}
      />
      <Content useRight>
        <div className={classStyle.cards}>
          {/**如果数据为空，展示空卡片 */}
          {data.length === 0 ? (
            <EmptyCard
              title={'当前暂无任何的开屏'}
              guide={'创建一个开屏以供于展示'}
              actionText={'创建开屏'}
            />
          ) : null}

          {renderCards(
            <Icons.Sun />,
            '使用中',
            data
              .filter((item) => dayjs().isBetween(item.start, item.end))
              .sort((a, b) => dayjs(b.start).valueOf() - dayjs(a.start).valueOf())
          )}

          {renderCards(
            <Icons.Sunrise />,
            '计划中',
            data
              .filter((item) => dayjs().isBefore(item.start))
              .sort((a, b) => dayjs(b.start).valueOf() - dayjs(a.start).valueOf())
          )}

          {renderCards(
            <Icons.Sunset />,
            '已过期',
            data
              .filter((item) => dayjs().isAfter(item.end))
              .sort((a, b) => dayjs(b.start).valueOf() - dayjs(a.start).valueOf())
          )}
        </div>
        <Spacer x={3} />
        <div className={classStyle.suggestions}>
          <Text h4 style={{ marginBottom: 0 }}>
            推荐阅读
          </Text>
          <Spacer />
          <div>
            {suggestions.map((item) => (
              <Fragment key={item.link}>
                <a href={item.link} target="_blank" rel="noreferrer">
                  <Card hoverable>
                    <div className={classStyle.card}>
                      <div style={{ marginRight: 20 }}>
                        <Avatar size={50} src={item.img} />
                      </div>
                      <Tooltip text={item.title} style={{ flex: 1, marginRight: 5 }}>
                        <Text className={classStyle.cardTitle}>{item.title}</Text>
                      </Tooltip>
                      <Icons.ArrowRight style={{ marginLeft: 'auto' }} />
                    </div>
                  </Card>
                </a>
                <Spacer y={0.5} />
              </Fragment>
            ))}
          </div>
        </div>
      </Content>
      <PhotoSlider
        className={classStyle.img}
        images={listRef.current.reduce<dataType[]>((acc, cur) => {
          const item = data.find((i) => cur === i.id);
          if (!item) return acc;

          return [...acc, { src: item.image ?? '', intro: item.title ?? '' }];
        }, [])}
        visible={showView}
        onClose={() => setShowView(false)}
        index={photoIndex}
        onIndexChange={setPhotoIndex}
      />
    </>
  );
};

const useStyles = makeStyles((ui) => ({
  columnItem: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    marginTop: '8px'
  },
  cards: {
    flex: 1,
    width: '100%'
  },
  suggestions: {
    minWidth: '30%',
    maxWidth: '35%'
  },
  card: {
    display: 'flex',
    justifyContent: 'row',
    alignItems: 'center'
  },
  cardTitle: {
    width: 160,
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontWeight: 500
  },
  [`@media screen and (max-width: ${ui.layout.pageWidthWithMargin})`]: {
    suggestions: {
      flex: 1,
      minWidth: '100%'
    }
  }
}));

export default Splash;
