import { useMedia } from 'react-use';
import { useTheme } from '@geist-ui/react';

/**
 * 根据屏幕尺寸判断平台
 */
export const usePlatform = () => {
  const ui = useTheme();
  const isPC = useMedia(`(min-width: ${ui.layout.pageWidthWithMargin})`);

  return {
    platform: isPC ? 'pc' : 'mobile',
    onlyPCShow: <T>(content: T) => (isPC ? content : null)
  };
};
