import Menu from './components/Menu';
import Home from './pages/Home';
import Footer from './components/Footer';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Notfound from './pages/404';
import About from './pages/About';
import Dev from './pages/Dev'
import Life from './pages/Life';
import Splash from './pages/Splash';

const Dashboard = () => {
  return (
    <Router>
      <Menu />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/about" component={About} />
        <Route path="/dev" component={Dev} />
        <Route path="/life" component={Life} />
        <Route path="/splash" component={Splash} />
        <Route path="*" component={Notfound} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default Dashboard;
