import { Spacer, Text, Collapse } from '@geist-ui/react';
import makeStyles from '../makeStyles';
import Header from '../components/Header';
import Content from '../components/Content';

const Life = () => {
  const classStyle = useStyles();

  return (
    <>
      <Header
        title="School Life"
        description="编辑南大家园校园生活页面"
        actions={[{ name: '预览' }, { name: '保存并发布', type: 'success' }]}
      />
      <Content>
        <Collapse.Group>
          <Collapse title="超文本标记语言">
            <Text>
              HTML（超文本标记语言——HyperText Markup Language）是构成 Web 世界的一砖一瓦。
              它定义了网页内容的含义和结构。除 HTML
              以外的其它技术则通常用来描述一个网页的表现与展示效果（如 CSS），或功能与行为（如
              JavaScript）。
            </Text>
          </Collapse>
          <Collapse title="层叠样式表">
            <Text>
              层叠样式表 (Cascading Style Sheets，缩写为 CSS），是一种 样式表 语言， 用来描述 HTML
              或 XML（包括如 SVG、MathML、XHTML 之类的 XML 分支语言）文档的呈现。 CSS
              描述了在屏幕、纸质、音频等其它媒体上的元素应该如何被渲染的问题。
            </Text>
          </Collapse>
        </Collapse.Group>
        <Spacer y={3} />
      </Content>
    </>
  );
};

const useStyles = makeStyles((ui) => ({
  titleContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  columnItem: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    marginTop: '8px'
  }
}));

export default Life;
