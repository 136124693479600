import { createUseStyles } from 'react-jss';
import { GeistUIThemes, useTheme } from '@geist-ui/react';

type Style<T = any> = ((ui: GeistUIThemes) => T) | T

type UseStyles<T> = () => Record<Extract<keyof T, string>, string>

const useStyles = (styles: Style) => {
  const theme: GeistUIThemes = useTheme();
  if (typeof styles === 'function') {
    styles = styles(theme);
  }
  return createUseStyles(styles)();
};

const makeStyles = <T>(styles: Style<T>): UseStyles<T> => {
  return (() => useStyles(styles)) as any;
}

export default makeStyles