import { FC, HTMLAttributes } from 'react';
import makeStyles from '../makeStyles';

interface Props extends HTMLAttributes<HTMLDivElement> {
  useRight?: boolean;
}

/**
 * 内容样式
 */
const Content: FC<Props> = ({ children = null, useRight = false }) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <div className={useRight ? classes.rowStretch : classes.row}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles((ui) => ({
  content: {
    padding: `24px calc(${ui.layout.pageMargin} * 1.5)`,
    width: ui.layout.pageWidthWithMargin,
    maxWidth: '100%',
    minHeight: '50vh',
    boxSizing: 'border-box',
    margin: '0 auto'
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  rowStretch: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    minWidth: 1,
    maxWidth: '100%',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  [`@media screen and (max-width: ${ui.layout.pageWidthWithMargin})`]: {
    rowStretch: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  }
}));

export default Content;
