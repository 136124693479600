import {
  Spacer,
  Table,
  Button,
  Tag,
  ButtonGroup,
  Avatar,
  Tooltip,
  useModal,
  Modal,
  User
} from '@geist-ui/react';
import makeStyles from '../makeStyles';
import Header from '../components/Header';
import Content from '../components/Content';
import * as Icons from 'react-feather';
import { usePlatform } from '../hooks/usePlatform';
import { Fragment, useState } from 'react';
import Title from '../components/Title';

const members = [
  {
    avatar: 'https://avatars.githubusercontent.com/u/25416941?v=4',
    name: '孙翔宇',
    classes: '物理 181 班',
    groups: [
      { id: '2333', name: '失物招领', duty: '前端开发' },
      { id: '2334', name: '空闲教室', duty: '设计师' }
    ]
  },
  {
    avatar: 'https://avatars.githubusercontent.com/u/21271495?v=4',
    name: '炫狗',
    classes: '计算机 183 班',
    groups: [{ id: '2335', name: 'iNCU 5.0', duty: '后端开发' }]
  },
  {
    avatar: 'https://avatars.githubusercontent.com/u/42794622?v=4',
    name: 'Jackeylove',
    classes: '数学 182 班',
    groups: [{ id: '2334', name: '空闲教室', duty: '运营' }]
  }
];

const groups = [
  { id: '2333', name: '失物招领' },
  { id: '2334', name: '空闲教室' },
  { id: '2335', name: 'iNCU 5.0' }
];

const About = () => {
  const classStyle = useStyles();
  const { platform, onlyPCShow } = usePlatform();
  const { setVisible, bindings } = useModal();
  const [detail, setDetail] = useState<any>({});

  const operation = (actions: any) => {
    return (
      <ButtonGroup size="mini" vertical={platform !== 'pc'} type="warning" ghost>
        <Button icon={<Icons.Edit3 />} onClick={() => actions.update()}>
          {onlyPCShow('编辑')}
        </Button>
        <Button icon={<Icons.X />} onClick={() => actions.remove()}>
          {onlyPCShow('删除')}
        </Button>
      </ButtonGroup>
    );
  };

  const renderModal = () => {
    return (
      <Modal {...bindings}>
        <div style={{ width: '100%', marginBottom: 12 }}>
          <Avatar src={detail.avatar} size={120}></Avatar>
        </div>
        <Modal.Title>{detail.name}</Modal.Title>
        <Modal.Subtitle>{detail.classes}</Modal.Subtitle>
        {/* <Modal.Content>
          <Text>{detail.name}</Text>
          <Text>{detail.classes}</Text>
        </Modal.Content> */}
        <Modal.Action passive onClick={() => setVisible(false)}>
          返回
        </Modal.Action>
        <Modal.Action>编辑</Modal.Action>
      </Modal>
    );
  };

  return (
    <>
      <Header title="About US" description="所有参与并建设南大家园项目的同学们" />
      <Content style={{ flexDirection: 'column' }}>
        {/** 项目组 */}
        <Title
          icon={<Icons.Users />}
          title={'项目组'}
          addTitle={'添加项目组'}
          addIcon={<Icons.Plus />}
        />
        <Table
          data={groups.map((item) => ({
            ...item,
            operation
          }))}
        >
          <Table.Column prop="id" label="id" width={platform === 'pc' ? 100 : 30} />
          <Table.Column prop="name" label="名称" />
          <Table.Column prop="operation" label="操作" width={platform === 'pc' ? 160 : 30} />
        </Table>

        <Spacer y={3} />

        {/** 所有成员 */}
        <Title
          icon={<Icons.User />}
          title={'所有成员'}
          addTitle={'添加成员'}
          addIcon={<Icons.Plus />}
        />
        <Table
          onRow={(row, index) => {
            setVisible(true);
            setDetail(members[index]);
          }}
          data={members.map((item) => ({
            name: (
              <User name={item.name} src={item.avatar} style={{ paddingLeft: 0 }}>
                {item.classes}
              </User>
            ),
            operation,
            groups: (
              <div className={classStyle.columnItem}>
                {item.groups.slice(0, 4).map((group) => (
                  <Fragment key={group.id}>
                    <Tooltip text={group.duty}>
                      <Tag style={{ marginBottom: '8px' }}>{group.name}</Tag>
                    </Tooltip>
                    <Spacer x={0.4} />
                  </Fragment>
                ))}
              </div>
            )
          }))}
        >
          <Table.Column prop="name" label="姓名" width={platform === 'pc' ? 160 : 20} />
          <Table.Column prop="groups" label="项目组" />
          <Table.Column prop="operation" label="操作" width={platform === 'pc' ? 160 : 30} />
        </Table>

        <Spacer y={3} />
      </Content>
      {renderModal()}
    </>
  );
};

const useStyles = makeStyles((ui) => ({
  columnItem: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    marginTop: '8px'
  }
}));

export default About;
