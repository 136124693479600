import makeStyles from '../makeStyles';
import { Text } from '@geist-ui/react';

const useStyles = makeStyles((ui) => ({
  root: {
    backgroundColor: ui.palette.accents_1,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const Notfound = () => {
  const classStyle = useStyles();

  return (
    <div className={classStyle.root}>
      <Text h3>🚧 Building...</Text>
    </div>
  );
};

export default Notfound;
