import create, { State, StateCreator } from 'zustand';
import produce, { Draft } from 'immer';
import { devtools } from 'zustand/middleware';
import { pipe } from 'ramda';
import { getDefaultTheme } from './utils';

const immer = <T extends State>(
  config: StateCreator<T, (fn: (draft: Draft<T>) => void) => void>
): StateCreator<T> => (set, get, api) => config((fn) => set(produce<T>(fn)), get, api);

const createStore = pipe(immer, devtools, create);

interface ConfigState extends State {
  themeType: 'light' | 'dark';
  username: string;
  toggleDarkMode: () => void;
  changeValue: <T extends keyof ConfigState>(key: T, value: ConfigState[T]) => void;
}

export const useStore = createStore<ConfigState>((set) => ({
  themeType: getDefaultTheme(),
  username: '孙翔宇',
  toggleDarkMode: () =>
    set((state) => {
      state.themeType = state.themeType === 'dark' ? 'light' : 'dark';
    }),
  changeValue: (key, value) =>
    set((state) => {
      state[key] = value;
    })
}));
