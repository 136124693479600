import makeStyles from '../makeStyles';
import {
  Grid,
  Card,
  Text,
  Button,
  Dot,
  Spacer,
  Tooltip,
  useModal,
  Modal,
  Input,
  Tag,
  useInput,
  ButtonGroup
} from '@geist-ui/react';
import Header from '../components/Header';
import Content from '../components/Content';
import * as Icons from 'react-feather';
import QRCode from 'react-qr-code';

enum Status {
  dev = 0,
  test = 1,
  prod = 2
}

const textMap = {
  [Status.dev]: '开发中',
  [Status.test]: '测试中',
  [Status.prod]: '已发布'
};

const typeMap = {
  [Status.dev]: 'default',
  [Status.test]: 'warning',
  [Status.prod]: 'success'
};

const data = [
  { name: '南大市场', description: '你需要的都在这里', status: 2 },
  { name: '失物招领', description: '找回它变得很容易', status: 1 },
  { name: 'Needing', description: '为了能做任何事', status: 0 }
];

const Dev = () => {
  const classStyle = useStyles();
  const { state: qrUrl, bindings: inputBindings } = useInput('127.0.0.1:3000');
  const { setVisible, bindings } = useModal();

  const renderModal = () => {
    return (
      <Modal {...bindings}>
        <Modal.Title>扫一扫</Modal.Title>
        <Modal.Subtitle>使用南大家园扫码调试</Modal.Subtitle>
        <Modal.Content className={classStyle.flexColumnCenter}>
          <div className={classStyle.qrContainer}>
            <div className={classStyle.qr}>
              <QRCode value={`http://${qrUrl}`} />
            </div>
          </div>
          <Spacer y={1.2} />
          <Input
            {...inputBindings}
            clearable
            placeholder="输入想要跳转的链接"
            label="http://"
            width={'278px'}
          />
        </Modal.Content>
        <Modal.Action passive onClick={() => setVisible(false)}>
          返回
        </Modal.Action>
      </Modal>
    );
  };

  const renderStatus = (status: Status) => {
    return (
      <Tag style={{ margin: '6px 0' }} type={typeMap[status] as any}>
        <Dot type={typeMap[status] as any} />
        {textMap[status]}
      </Tag>
    );
  };

  const renderCreateApp = () => {
    return (
      <Grid xs={24} md={6}>
        <Card className={classStyle.addContainer} hoverable>
          <div className={classStyle.addCard}>
            <Icons.Plus style={{ marginBottom: 8 }} size={32} />
            <Text h5 style={{ marginBottom: 0 }}>
              添加应用
            </Text>
          </div>
        </Card>
      </Grid>
    );
  };

  const renderTitle = (name: string) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ width: '24px', height: '24px' }}>
          <Icons.Grid />
        </div>
        <Tooltip text={name} style={{ display: 'flex', overflow: 'hidden' }}>
          <Text h4 className={classStyle.title}>
            {name}
          </Text>
        </Tooltip>
      </div>
    );
  };

  return (
    <>
      <Header
        title="DEV"
        description="南大家园微应用开发工具台"
        actions={[
          { name: '真机调试', onPress: () => setVisible(true) },
          { name: '发布应用', onPress: () => {}, type: 'success' }
        ]}
      />
      <Content>
        <Grid.Container gap={2} justify="flex-start">
          {renderCreateApp()}
          {data.map((item) => {
            return (
              <Grid xs={24} md={6} key={item.name}>
                <Card shadow className={classStyle.card}>
                  {renderTitle(item.name)}
                  <Text className={classStyle.desc} type="secondary">{item.description}</Text>
                  <Card.Footer className={classStyle.footer} disableAutoMargin>
                    {renderStatus(item.status)}
                    <ButtonGroup size={'mini'} ghost style={{ margin: 0 }}>
                      <Button auto icon={<Icons.Edit3 />}></Button>
                      <Button auto icon={<Icons.X />}></Button>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </Grid>
            );
          })}
        </Grid.Container>
      </Content>
      {renderModal()}
    </>
  );
};

const useStyles = makeStyles((ui) => ({
  container: {
    backgroundColor: ui.palette.accents_1
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  qr: {
    width: '256px',
    height: '256px'
  },
  qrContainer: {
    padding: 11,
    borderRadius: 13,
    backgroundColor: '#fff'
  },
  title: {
    marginBottom: 0,
    marginLeft: 6,
    display: 'inline-block',
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& h4': {
      margin: 0
    }
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  addCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  addContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  desc: {
    fontSize: 14
  }
}));

export default Dev;
