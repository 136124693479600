import { Text, Button, Spacer } from '@geist-ui/react';
import { noop } from 'lodash';
import { FC, Fragment } from 'react';
import makeStyles from '../makeStyles';

interface Props {
  title: string;
  description: string;
  actions?: {
    name: string;
    onPress?: () => void;
    icon?: JSX.Element;
    disabled?: boolean; // false
    type?: string;
  }[]; // []
}

/**
 * 页头组件
 *
 */
const Header: FC<Props> = ({ title, description, actions = [] }) => {
  const classes = useStyles();

  const renderActions = () => {
    if (!actions?.length) return null;

    return (
      <div className={classes.actionsWrapper}>
        {actions.map((item, index) => {
          return (
            <Fragment key={item.name}>
              {index ? <Spacer x={0.5} /> : null}
              <Button
                auto
                size="small"
                icon={item.icon}
                style={{ fontWeight: 500 }}
                onClick={item?.onPress ?? noop}
                disabled={item.disabled}
                type={item.type as any}
              >
                {item.name}
              </Button>
            </Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.name}>
          <div className={classes.title}>
            <Text h2 className={classes.text}>
              {title}
            </Text>
            <Text type="secondary" style={{ margin: '3px 0' }}>
              {description}
            </Text>
          </div>
        </div>
      </div>
      {renderActions()}
    </div>
  );
};

const useStyles = makeStyles((ui) => ({
  root: {
    borderBottom: `solid 1px ${ui.palette.accents_2}`
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    width: ui.layout.pageWidthWithMargin,
    maxWidth: '100%',
    padding: `calc(${ui.layout.gap} * 2.5) calc(${ui.layout.pageMargin} * 1.5)`,
    boxSizing: 'border-box',
    margin: '0 auto'
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 24,
    marginTop: -22,
    width: ui.layout.pageWidthWithMargin,
    maxWidth: '100%',
    padding: `0 calc(${ui.layout.pageMargin} * 1.5)`,
    boxSizing: 'border-box',
    margin: '0 auto'
  },
  name: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    maxWidth: '100%'
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  text: {
    lineHeight: 1,
    fontWeight: 500
  },
  [`@media screen and (max-width: ${ui.layout.pageWidthWithMargin})`]: {
    title: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 24,
      marginTop: -12,
      width: ui.layout.pageWidthWithMargin,
      maxWidth: '100%',
      padding: `0 calc(${ui.layout.pageMargin} * 1.5)`,
      boxSizing: 'border-box',
      margin: '0 auto'
    },
    decs: {
      display: 'inline-block',
      fontSize: '14px',
      maxWidth: '100%',
      minWidth: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      wordWrap: 'normal'
    }
  }
}));

export default Header;
