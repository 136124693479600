import React from 'react';
import { GeistProvider, CssBaseline } from '@geist-ui/react';
import ReactDOM from 'react-dom';
import Dashboard from './Dashboard';
import * as serviceWorker from './serviceWorker';
import { JssProvider } from 'react-jss';
import { useStore } from './store';

import 'inter-ui/inter.css';
import 'react-photo-view/dist/index.css';
import './initService';

const App = () => {
  const [themeType, changeValue] = useStore((state) => [state.themeType, state.changeValue]);

  if (window.matchMedia) {
    const colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    colorSchemeQuery.onchange = (e) => changeValue('themeType', e.matches ? 'dark' : 'light');
  }

  return (
    <JssProvider id={{ minify: true }}>
      <GeistProvider themeType={themeType}>
        <CssBaseline />
        <Dashboard />
      </GeistProvider>
    </JssProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register();
