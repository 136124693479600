import { useState, useEffect } from 'react';
import { Avatar, Button, Tabs, useTheme, Popover, Link } from '@geist-ui/react';
import { useStore } from '../store';
import { useHistory } from 'react-router';
import { getAvatarText } from '../utils';
import * as Icons from 'react-feather';
import makeStyles from '../makeStyles';

const useStyles = makeStyles((ui) => ({
  header: {
    width: ui.layout.pageWidthWithMargin,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: ui.palette.background,
    fontSize: 16,
    height: 60,
    zIndex: 15
  },
  headerContent: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0 ${ui.layout.pageMargin}`
  },
  headerTitle: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10
  },
  nav: {
    position: 'sticky',
    top: 0,
    backgroundColor: ui.palette.background,
    borderBottom: `solid 1px ${ui.palette.accents_2}`,
    zIndex: 15
  },
  navFixed: {
    borderBottom: ui.type === 'light' && 'none',
    boxShadow: ui.type === 'light' && 'rgba(0, 0, 0, 0.1) 0 0 15px 0'
  },
  navContent: {
    width: ui.layout.pageWidthWithMargin,
    maxWidth: '100%',
    height: '100%',
    margin: '0 auto',
    '& .tabs header': {
      padding: `0 ${ui.layout.pageMargin}`,
      borderBottom: 'none !important',
      flexWrap: 'nowrap !important',
      overflowY: 'hidden',
      overflowX: 'auto',
      overflow: '-moz-scrollbars-none',
      '-ms-overflow-style': 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    '& .content': {
      display: 'none !important'
    },
    '& .tab': {
      padding: '12px !important',
      margin: '0 !important',
      fontSize: '14px !important'
    }
  },
  sidebar: {
    display: 'flex',
    alignItems: 'center !important'
  },
  themeIcon: {
    width: '40px !important',
    height: '40px !important',
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    marginRight: 5,
    padding: '0 !important'
  },
  popover: {
    width: '180px !important'
  }
}));

const Menu = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [fixed, setFixed] = useState(false);
  const isDark = theme.type === 'dark';
  const history = useHistory();
  const [pathname, setPathname] = useState(history.location.pathname);
  const { toggleDarkMode, username } = useStore((state) => ({
    toggleDarkMode: state.toggleDarkMode,
    username: state.username
  }));

  useEffect(() => {
    history.listen((location) => {
      setPathname(location.pathname);
    });
  }, [history]);

  const popoverContent = () => (
    <>
      <Popover.Item title>
        <span>{username}</span>
      </Popover.Item>
      <Popover.Item>
        <Link onClick={() => history.push('/setting')}>设置</Link>
      </Popover.Item>
      <Popover.Item>
        <Link>其他</Link>
      </Popover.Item>
      <Popover.Item line />
      <Popover.Item>
        <Link>登出</Link>
      </Popover.Item>
    </>
  );

  useEffect(() => {
    const scrollHandler = () => {
      const shouldFixed = document.documentElement.scrollTop > 60;
      if (fixed !== shouldFixed) setFixed(shouldFixed);
    };
    document.addEventListener('scroll', scrollHandler);
    return () => document.removeEventListener('scroll', scrollHandler);
  }, [fixed]);

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <div style={{ display: 'flex' }}>
            <div className={classes.headerTitle}>
              <Icons.Triangle size={18} style={{ marginRight: 13 }} /> Plot Toolbox
            </div>
          </div>
          <div className={classes.sidebar}>
            <Button
              aria-label="Toggle Dark mode"
              className={classes.themeIcon}
              auto
              type="abort"
              onClick={toggleDarkMode}
            >
              {isDark ? <Icons.Sun size={16} /> : <Icons.Moon size={16} />}
            </Button>
            <Popover
              content={popoverContent}
              placement="bottomEnd"
              portalClassName={classes.popover}
            >
              <Avatar text={getAvatarText(username)} />
            </Popover>
          </div>
        </div>
      </div>
      <nav className={classes.nav + ' ' + (fixed ? classes.navFixed : '')}>
        <div className={classes.navContent}>
          <Tabs
            initialValue={history.location.pathname}
            value={pathname}
            onChange={(e) => history.push(e)}
          >
            <Tabs.Item label="仪表盘" value="/" />
            <Tabs.Item label="导航" value="/nav" />
            <Tabs.Item label="开发" value="/dev" />
            <Tabs.Item label="生活" value="/life" />
            <Tabs.Item label="开屏" value="/splash" />
            <Tabs.Item label="地图" value="/map" />
            <Tabs.Item label="关于" value="/about" />
            <Tabs.Item label="设置" value="/setting" />
          </Tabs>
        </div>
      </nav>
    </>
  );
};

export default Menu;
