export const getDefaultTheme = () =>
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

export const getAvatarText = (username: string) => {
  switch (username.length) {
    case 0:
      return '姓名';
    case 1:
      return username;
    case 2:
      return username[1];
    default:
      return username.slice(1, 3);
  }
};
