import { Spacer, Text, Button } from '@geist-ui/react';
import { FC } from 'react';
import { usePlatform } from '../hooks/usePlatform';
import { noop } from 'lodash';
import makeStyles from '../makeStyles';

interface Props {
  title: string;
  icon?: JSX.Element;
  addIcon?: JSX.Element;
  addTitle?: string;
  onAdd?: () => void;
}

const Title: FC<Props> = ({ title, icon, addIcon, addTitle, onAdd = noop }) => {
  const { platform } = usePlatform();
  const classStyle = useStyles();

  return (
    <>
      <div className={classStyle.titleContainer}>
        <div className={classStyle.title}>
          {icon}
          {icon ? <Spacer x={0.3} /> : null}
          <Text h4 style={{ marginBottom: 0 }}>
            {title}
          </Text>
        </div>
        {addTitle ? (
          <Button
            auto
            size="small"
            iconRight={addIcon}
            onClick={onAdd}
          >
            {platform === 'pc' ? addTitle : null}
          </Button>
        ) : null}
      </div>
      <Spacer />
    </>
  );
};

const useStyles = makeStyles(() => ({
  titleContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  columnItem: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    marginTop: '8px'
  }
}));

export default Title;
