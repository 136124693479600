import { GeistUIThemes, Button, Text, Card } from '@geist-ui/react';
import makeStyles from '../makeStyles';
import type { FC } from 'react';

interface Props {
  title: string;
  guide: string;
  actionText?: string;
  action?: () => void;
}

const EmptyCard: FC<Props> = ({ title, guide, actionText, action }) => {
  const classes = useStyles();

  return (
    <Card>
      <div className={classes.card}>
        <Text h3 style={{ margin: 0, textAlign: 'center' }}>
          {title}
        </Text>
        <Text type="secondary" style={{ margin: '12px 0' }}>
          {guide}
        </Text>
        <Button type="secondary" className={classes.button} onClick={action}>
          {actionText}
        </Button>
      </div>
    </Card>
  );
};

const useStyles = makeStyles((ui: GeistUIThemes) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 270
  },
  button: {
    fontWeight: 500,
    marginTop: 12
  }
}));

export default EmptyCard;
