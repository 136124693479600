import {
  Button,
  Text,
  Link,
  Image,
  Fieldset,
  Capacity,
  Tag,
  Description,
  Spacer
} from '@geist-ui/react';
import makeStyles from '../makeStyles';
import * as Icons from 'react-feather';
import dayjs from 'dayjs';
import { usePlatform } from '../hooks/usePlatform';

interface Props {
  id: string;
  image: string;
  title: string;
  webURL: string;
  start: number; // valueof
  end: number; // valueof
  openImage: () => void;
}

const SplashCard = ({ image, title, webURL, start, end, openImage }: Props) => {
  const classes = useStyles();
  const { platform } = usePlatform();

  const during = dayjs().valueOf() - start;
  const value = (during / (end - start)) * 100;

  const formatStr = platform === 'pc' ? 'YYYY年MM月DD日 HH:mm' : 'YYYY-MM-DD';

  return (
    <Fieldset className={classes.card}>
      <Image
        onClick={openImage}
        src={image}
        height={220}
        className={classes.image}
        style={{ objectFit: 'cover' }}
      />
      <Fieldset.Content>
        <Fieldset.Title style={{ marginBottom: 10 }}>{title}</Fieldset.Title>
        <Description
          title="投放时间"
          content={
            <Text type="secondary">
              <Tag type="lite" style={{ fontWeight: 500 }}>
                {dayjs(start).format(formatStr)}
              </Tag>
              {' — '}
              <Tag type="lite" style={{ fontWeight: 500 }}>
                {dayjs(end).format(formatStr)}
              </Tag>
            </Text>
          }
        />
        <Spacer y={0.8} />
        <Description title="整体进度" content={<Capacity value={value} />} />
      </Fieldset.Content>
      <Fieldset.Footer>
        <Fieldset.Footer.Status>
          <Icons.Globe size={14} />
          <Link className={classes.repo} href={webURL} target="_blank">
            {webURL}
          </Link>
        </Fieldset.Footer.Status>
        <Fieldset.Footer.Actions>
          <Button auto size="mini">
            编辑
          </Button>
        </Fieldset.Footer.Actions>
      </Fieldset.Footer>
    </Fieldset>
  );
};

const useStyles = makeStyles((ui) => ({
  card: {
    padding: '0 !important',
    marginBottom: `0 !important`,
    flex: 1
  },
  image: {
    transition: 'opacity .3s ease',
    cursor: 'pointer',
    borderRadius: '0 0 0 0 !important',
    '&:hover': {
      opacity: 0.65
    }
  },
  repo: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: '6px !important'
  }
}));

export default SplashCard;
